import { Icon } from "@mui/material";
import AppliedJobs from "pages/appliedjobs/appliedjobs";
import Enquiries from "pages/enquiries/enquiries";
import Jobs from "pages/jobs/jobs";
import NewJob from "pages/newjob/newjob";
import SignIn from "pages/sign-in";
import Dashboard from "./pages/dashboard";

const routes = [
  {
    type: "page",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Applied Jobs",
    key: "applied-jobs",
    icon: <Icon fontSize="small">school</Icon>,
    route: "/applied-jobs",
    component: <AppliedJobs />,
  },

  {
    type: "collapse",
    name: "Enquiries",
    key: "enquiries",
    icon: <Icon fontSize="small">rate_review</Icon>,
    route: "/enquiries",
    component: <Enquiries />,
  },

  {
    type: "collapse",
    name: "Jobs",
    key: "jobs",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/jobs",
    component: <Jobs />,
    children: [
      {
        type: "collapse",
        name: "Create New Job",
        key: "jobs/new-job",
        icon: <Icon fontSize="small">add</Icon>,
        route: "/jobs/new-job",
        component: <NewJob />,
      },
    ],
  },
];

export default routes;
