import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CategoryModal from "components/CategoryModal";
import ErrorPage from "components/Error";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { setChecked, useAdminPanelController } from "context";
import dayjs from "dayjs";
import ContentLayout from "layouts/content";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { editJobFormSchema } from "schema/editJobForm";
import { getJob, putJob } from "service/job";
import { dateForBackend } from "util/date";

const InputGroup = styled(MDInput)`
  .MuiOutlinedInput-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const EditJob = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [notification, setNotification] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [job, setJob] = useState({});
  const [message, setMessage] = useState(false);
  const openNotification = () => setNotification(true);
  const [controller, dispatch] = useAdminPanelController();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getJob(id);
        setChecked(dispatch, data?.categories[0]?.job_categories?.id);
        if (data) {
          setJob(data);
        }
      } catch (error) {
        if (error.message !== 404) {
          setMessage(true);
        } else {
          navigate("/not-found");
        }
      }
    };

    fetchData();
  }, [id]);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(editJobFormSchema),
  });
  const handleEditJob = async (data) => {
    if (isSave) {
      return;
    }
    setIsSave(true);
    const job_expiry = dateForBackend(data?.job_expiry);
    const category = categories.find((item) => item?.name === data?.category);
    if (job?.job_expiry !== job_expiry) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (data?.job_expiry >= today) {
        const response = await putJob(
          { ...data, id: category.id, job_expiry },
          id
        );
        if (response) {
          openNotification();
        }
      } else {
        setError("job_expiry", {
          type: "manual",
          message: "Date must be equal to or greater than today",
        });
      }
    } else {
      const response = await putJob(
        { ...data, id: category.id, job_expiry },
        id
      );
      if (response) {
        openNotification();
      }
    }
    setIsSave(false);
  };
  const renderNotification = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Job edited successfully"
      dateTime="now"
      open={notification}
      onClose={() => {
        setNotification(false);
      }}
      close={() => {
        setNotification(false);
      }}
      bgWhite
    />
  );

  if (message) {
    return <ErrorPage />;
  }
  return (
    <>
      {job.job_title && (
        <ContentLayout>
          <MDBox
            maxWidth="sm"
            py={3}
            sx={{
              marginRight: {
                xs: 0,
                sm: 5,
                lg: 20,
                xl: 40,
              },
              marginLeft: {
                xs: 0,
                sm: 5,
                lg: 200,
                xl: 40,
              },
            }}
          >
            <Card>
              <Grid
                container
                sx={{
                  padding: "50px",
                }}
                justifyContent={"center"}
                gap={2}
              >
                <Grid item xs={12}>
                  <MDBox
                    sx={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <MDTypography variant="h4">Edit Job</MDTypography>
                  </MDBox>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Job Title"
                    fullWidth
                    {...register("job_title")}
                    defaultValue={job?.job_title}
                    helperText={errors?.job_title?.message || ""}
                    error={Boolean(errors?.job_title)}
                  />
                </Grid>

                <Grid item xs={12} className="cusmar1">
                  <FormControl fullWidth>
                    <Controller
                      name="category"
                      control={control}
                      defaultValue={
                        job?.categories && job?.categories?.length > 0
                          ? job?.categories[0]?.job_categories?.name
                          : ""
                      }
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel
                            shrink={!!field.value}
                            error={Boolean(errors?.category)}
                            id="input_categoryselected"
                            style={{ backgroundColor: "white" }}
                          >
                            Added Category
                          </InputLabel>
                          <TextField
                            {...field}
                            InputProps={{
                              readOnly: true,
                            }}
                            error={Boolean(errors?.category)}
                            helperText={errors?.category?.message || ""}
                          />
                        </FormControl>
                      )}
                    />
                  </FormControl>
                </Grid>

                <CategoryModal
                  setValue={setValue}
                  setCategories={setCategories}
                  categories={categories}
                />

                <Grid item xs={12} className="cusmar1">
                  <TextField
                    label="Job Description"
                    {...register("job_description")}
                    InputProps={{
                      style: {
                        backgroundColor: "white",
                        paddingTop: "7px",
                      },
                    }}
                    multiline
                    rows={5}
                    fullWidth
                    defaultValue={job?.job_description}
                    helperText={errors?.job_description?.message || ""}
                    error={Boolean(errors?.job_description)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="job_expiry"
                    defaultValue={dayjs(job?.job_expiry)}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <FormControl fullWidth variant="outlined">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Last date to apply "
                              sx={{
                                "& .MuiFormLabel-root": {
                                  backgroundColor: "white",
                                  padding: "0.2rem",
                                },
                              }}
                              format="DD-MM-YYYY"
                              slotProps={{
                                textField: {
                                  variant: "outlined",
                                  helperText: errors.job_expiry?.message || "",
                                  error: Boolean(errors.job_expiry),
                                },
                              }}
                              disablePast
                              value={field.value ? dayjs(field.value) : null}
                              inputRef={field.ref}
                              onChange={(date) => {
                                field.onChange(date ? date.toDate() : null);
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} className="ends">
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={handleSubmit(handleEditJob)}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            {renderNotification}
          </MDBox>
        </ContentLayout>
      )}
    </>
  );
};

export default EditJob;
