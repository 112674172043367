import * as Yup from "yup";

export const editJobFormSchema = Yup.object({
  job_title: Yup.string()
    .trim()
    .required("Please enter job title")
    .min(3, "Title should be at least 3 characters"),
  category: Yup.string().required("Please select or add a category"),
  job_description: Yup.string()
    .trim()
    .required("Please provide a description")
    .min(3, "Message should be at least 3 characters")
    .max(500, "Message must be at most 500 characters"),
  job_expiry: Yup.date("Invalid date format")
    .typeError("Invalid date format")
    .nullable("Invalid date format")
    .transform((value, originalValue) => {
      if (originalValue === "") return null;
      return value;
    })
    .required("Last date to apply is required")
    .test("year-check", "Invalid date format", function (value) {
      if (!value) return true;
      const year = value.getFullYear();
      return year.toString().length === 4;
    }),
});
