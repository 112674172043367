import { CssBaseline, ThemeProvider } from "@mui/material";
import { setMiniSidenav, useAdminPanelController } from "context";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import theme from "./assets/theme";
import Sidenav from "./components/Sidenav";
import routes from "./routes";

import Toast from "components/ToastContainer";
import NotFoundPage from "pages/404";
import EditJob from "pages/editjob/editjob";
import JobDetails from "pages/jobdetails/jobdetails";
import NewJob from "pages/newjob/newjob";
import { CheckLogin, Protected } from "util/Protected";
import "./index.scss";

function App() {
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [controller, dispatch] = useAdminPanelController();
  const { miniSidenav, layout } = controller;
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.key === "sign-in") {
        return (
          <Route key={route.key} element={<CheckLogin />}>
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key}
            />
          </Route>
        );
      }
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route key={route.key} element={<Protected />}>
            <Route exact path={route.route} element={route.component} />
          </Route>
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "content" && <Sidenav routes={routes} />}
      <Routes>
        {getRoutes(routes)}
        <Route element={<Protected />}>
          <Route exact path="/jobs/new-job" element={<NewJob />} />
          <Route exact path="/jobs/:id" element={<JobDetails />} />
          <Route exact path="/jobs/edit-job/:id" element={<EditJob />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toast />
    </ThemeProvider>
  );
}

export default App;
