import { getUTC } from "util/date";
import toastMessage from "util/toast";
import axios from "../util/axios";
export const postJob = async (data) => {
  try {
    const response = await axios.post("/job_master", {
      job_title: data.job_title,
      job_description: data.job_description,
      job_expiry: data.job_expiry,
      category_id: [data.id],
      status: "Active",
    });
    return response?.data?.data?.job_master;
  } catch (error) {
    toastMessage(error.response?.data.error || "Something went wrong", "error");
  }
};

export const getAllJob = async (
  offset,
  limit,
  searchName,
  status,
  categoryId
) => {
  try {
    const utcOffset = getUTC();
    const params = {
      offset,
      limit,
      populate: JSON.stringify(["single_category.job_categories"]),
      sort: JSON.stringify([["created_at", "desc"]]),
    };

    const whereCondition = { active: true, timezone: utcOffset };
    if (searchName !== "" || status !== "") {
      if (searchName !== "") {
        whereCondition["$single_category.job_categories.name$"] = searchName;
        whereCondition["category_id"] = categoryId;
      }

      if (status !== "") {
        whereCondition.status = status;
      }
    }
    params.where = JSON.stringify(whereCondition);

    const response = await axios.get("/job_master", {
      params: params,
    });

    return response?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteJob = async (id) => {
  try {
    const response = await axios.put(`/job_master/${id}`, { active: false });

    return response?.data?.data?.job_master;
  } catch (error) {
    toastMessage(
      error?.response?.data?.error || "Something went wrong",
      "error"
    );
  }
};

export const getJob = async (id) => {
  try {
    const response = await axios.get(`/job_master/${id}`, {
      params: {
        populate: JSON.stringify(["categories.job_categories", "job"]),
      },
    });

    return response?.data?.data?.job_master;
  } catch (error) {
    if (error?.response?.status === 404) {
      throw new Error("404");
    } else {
      throw new Error(error);
    }
  }
};

export const putJob = async (data, id) => {
  try {
    const response = await axios.put(`/job_master/${id}`, {
      job_title: data.job_title,
      job_description: data.job_description,
      job_expiry: data.job_expiry,
      category_id: [data.id],
      status: "Active",
    });
    return response?.data?.data?.job_master;
  } catch (error) {
    toastMessage(
      error?.response?.data?.error || "Something went wrong",
      "error"
    );
  }
};
export const applicantsForAJob = async (id, offset, limit) => {
  try {
    const response = await axios.get("/user_jobs", {
      params: {
        offset,
        limit,
        where: JSON.stringify({
          job_id: id,
        }),
      },
    });

    return response?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const appliedJobs = async (offset, limit, status, searchName) => {
  try {
    const params = {
      offset,
      limit,
      populate: JSON.stringify(["job.single_category.job_categories"]),
      sort: JSON.stringify([["created_at", "desc"]]),
    };
    let whereCondition = {};
    if (searchName !== "" || status !== "") {
      if (searchName !== "") {
        whereCondition.searchName = searchName;
      }

      if (status !== "") {
        whereCondition["$job.status$"] = status;
      }
    }
    params.where = JSON.stringify(whereCondition);
    const response = await axios.get("/user_jobs", {
      params,
    });
    if (response) {
      const ids = response?.data?.data?.user_jobs?.map((item) => {
        return item.id;
      });
      const data = await axios.post("/user_jobs/update_view", {
        user_ids: ids,
      });
      if (!data) {
        throw new Error("something went wrong");
      }
    }
    return response?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const appliedJobsWithDeletedCategories = async (
  offset,
  limit,
  status
) => {
  try {
    const params = {
      offset,
      limit,
    };
    if (status !== "") {
      params.where = JSON.stringify({ status: status });
    }
    const response = await axios.get("/user_jobs/deleted_category_jobs", {
      params,
    });
    return {
      user_jobs: response?.data?.data?.user_jobs,
      count: response?.data?.data?.user_jobs[0]?.count,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteAppliedJob = async (id) => {
  try {
    const response = await axios.delete(`/user_jobs/${id}`);
    return response?.data?.data;
  } catch (error) {
    toastMessage(
      error?.response?.data?.error || "Something went wrong",
      "error"
    );
  }
};
