import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardNavbar from "components/Navbar";
// Data
import ContentLayout from "layouts/content";
import authorsTableData from "./data/authorsTableData";

import Divider from "@mui/material/Divider";

// @mui icons

import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";

import MDButton from "components/MDButton";

import Card from "@mui/material/Card";

import MDTypography from "components/MDTypography";

import DataTable from "components/Tables/DataTable";
// Data
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ErrorPage from "components/Error";
import Loading from "components/Loading";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { applicantsForAJob, getJob } from "service/job";
import { convertDate, getDate } from "util/date";

function JobDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const perPageContent = process.env.REACT_APP_PER_PAGE_CONTENT;
  const [job, setJob] = useState({});
  const [applicants, setApplicants] = useState([]);
  const [message, setMessage] = useState("");
  const [totalItems, setTotalItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const offset = (+currentPage - 1) * +perPageContent;
  const limit = perPageContent;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getJob(id);
        const applications = await applicantsForAJob(id, offset, limit);
        if (applications) {
          setApplicants(applications?.user_jobs || []);
          setTotalItems(applications?.count || null);
          setLoading(false);
        }
        if (data) {
          setJob({ ...data });
        }
      } catch (error) {
        if (error?.message !== 404) {
          setError(true);
        } else {
          navigate("/not-found");
        }
      }
    };

    fetchData();
  }, [offset, limit, id]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { columns, rows } = authorsTableData(
    applicants,
    setMessage,
    handleClickOpen
  );
  if (error) {
    return <ErrorPage />;
  }
  return (
    <ContentLayout>
      <DashboardNavbar />
      {loading ? (
        <Loading />
      ) : (
        <>
          <MDBox pt={6} pb={3}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Job Description"
                description={job?.job_description}
                info={{
                  JobTitle: job?.job_title,
                  Category:
                    job?.categories && job?.categories.length > 0
                      ? job?.categories[0]?.job_categories?.name
                      : null,
                  Applications: job?.job ? `${job?.job.length}` : null,
                  CreatedDate: job?.created_at
                    ? getDate(job?.created_at.split("T")[0])
                    : null,
                  LastDateToApply: convertDate(job?.job_expiry),
                  Status: job?.status,
                }}
                social={[]}
                action={{ route: "", tooltip: "Applied Jobs " }}
                shadow={false}
              />

              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </MDBox>

          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Grid item xs={12} md={4}>
                        {" "}
                        <MDTypography variant="h6" color="white">
                          Applied Jobs
                        </MDTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className={"custom_flex"}
                      >
                        {" "}
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {applicants?.length === 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingBottom: "1.5rem",
                        }}
                      >
                        <MDTypography
                          component="a"
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Oops !! No Result Found
                        </MDTypography>
                      </Box>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            {totalItems >= perPageContent && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalItems={totalItems}
              />
            )}
          </MDBox>
        </>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Comment"}</DialogTitle>
        <DialogContent
          maxWidth="md"
          sx={{
            minWidth: { xs: 300, sm: 400, md: 576 },
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          {message}
        </DialogContent>

        <DialogActions className="d-flex bpopbg">
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleClose}
            autoFocus
          >
            Done
          </MDButton>
        </DialogActions>
      </Dialog>
    </ContentLayout>
  );
}

export default JobDetails;
