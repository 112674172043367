import { Icon, Menu, MenuItem } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertDate, getDate } from "util/date";
// Images
const team =
  "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80";

export default function data(jobs, setId, setShow) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const ActionItem = ({ id }) => {
    const navigate = useNavigate();
    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => {
      setMenu(null);
    };
    const handleEdit = () => {
      setMenu(null);
      navigate(`/jobs/edit-job/${id}`);
    };
    const handleDelete = () => {
      setId(id);
      setShow(true);
      setMenu(null);
    };
    const renderMenu = (
      <Menu
        id="simple-menu"
        anchorEl={menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    );

    return (
      <Fragment>
        <MDBox color="text" px={2}>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={openMenu}
          >
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </Fragment>
    );
  };

  return {
    columns: [
      { Header: "Job Title", accessor: "jobtitle", align: "left" },
      { Header: "Category", accessor: "category", align: "left" },
      { Header: "Applications", accessor: "applications", align: "center" },
      { Header: "Created Date", accessor: "createddate", align: "center" },
      { Header: "Last date to apply", accessor: "applydate", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows:
      jobs?.length === 0
        ? [
            {
              jobtitle: null,

              category: null,

              applications: null,

              createddate: (
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  Oops !! No Result Found
                </MDTypography>
              ),

              applydate: null,

              status: <MDBox ml={-1}></MDBox>,
              action: null,
            },
          ]
        : jobs?.map((job) => {
            return {
              jobtitle: (
                <MDTypography
                  component="a"
                  href={`/jobs/${job?.id}`}
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {job?.job_title}
                </MDTypography>
              ),

              category: (
                <MDTypography
                  component="a"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {job?.single_category?.job_categories?.name || ""}
                </MDTypography>
              ),

              applications: (
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {job?.count}
                </MDTypography>
              ),

              createddate: (
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {job?.created_at
                    ? getDate(job?.created_at.split("T")[0])
                    : null}
                </MDTypography>
              ),

              applydate: (
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {job?.job_expiry ? convertDate(job?.job_expiry) : null}
                </MDTypography>
              ),

              status: (
                <MDBox ml={-1}>
                  <MDBadge
                    badgeContent={job?.status || null}
                    color={job?.status === "Active" ? "success" : "dark"}
                    variant="gradient"
                    size="sm"
                  />
                </MDBox>
              ),
              action: <ActionItem id={job?.id} />,
            };
          }),
  };
}
