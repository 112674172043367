import { Icon } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getDate } from "util/date";

// Images
const team =
  "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80";

export default function data(
  handleClickOpen,
  setMessage,
  enquiries,
  setId,
  setShow
) {
  const handleClick = (message) => {
    setMessage(message);
    handleClickOpen();
  };
  const handleDelete = (id) => {
    setShow(true);
    setId(id);
  };
  const handleOpen = () => {};
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "email", accessor: "email", align: "left" },
      { Header: "phone", accessor: "phone", align: "left" },
      { Header: "message", accessor: "message", align: "center" },
      { Header: "date", accessor: "date", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows:
      enquiries.length === 0
        ? [
            {
              name: null,
              email: null,

              phone: null,

              message: null,

              date: null,
              action: null,
            },
          ]
        : enquiries.map((enquiry) => {
            return {
              name: (
                <MDTypography
                  component="a"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {enquiry?.name}
                </MDTypography>
              ),

              email: (
                <MDTypography
                  component="a"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {enquiry?.email}
                </MDTypography>
              ),

              phone: (
                <MDTypography
                  component="a"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {enquiry?.contact}
                </MDTypography>
              ),

              message: (
                <MDTypography
                  component="a"
                  variant="caption"
                  sx={{ cursor: "pointer" }}
                  color="text"
                  fontWeight="medium"
                  onClick={() => handleClick(enquiry?.message)}
                >
                  <Icon fontSize="small">email</Icon>
                </MDTypography>
              ),

              date: (
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {enquiry?.created_at
                    ? getDate(enquiry?.created_at.split("T")[0])
                    : null}
                </MDTypography>
              ),
              action: (
                <MDTypography
                  component="a"
                  variant="caption"
                  sx={{ cursor: "pointer" }}
                  color="text"
                  fontWeight="medium"
                  onClick={() => handleDelete(enquiry?.id)}
                >
                  <Icon fontSize="small">delete</Icon>
                </MDTypography>
              ),
            };
          }),
  };
}
