import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MDTypography from "components/MDTypography";
import { setChecked, useAdminPanelController } from "context";
import { deleteCategory } from "service/category";
import toastMessage from "util/toast";
export default function ListChecked({
  setValue,
  category,
  setCategory,
  setCategoryId,
  setCategories,
  setOpen,
}) {
  const [controller, dispatch] = useAdminPanelController();
  const handleToggle = (value) => () => {
    if (value?.id === controller?.checked) {
      setChecked(dispatch, null);
      setValue("category", "");
    } else {
      setChecked(dispatch, value?.id);
      setValue("category", value.name, { shouldValidate: true });
      setOpen(false);
    }
  };

  const handleEdit = (category) => {
    setCategory(category?.name);
    setCategoryId(category?.id);
  };

  const handleDelete = async (id) => {
    const response = await deleteCategory(id);
    if (response) {
      const newCategories = category.filter(
        (item) => item?.id !== response?.id
      );
      setCategories(newCategories);
      setCategory("");
      setCategoryId(null);
      if (controller?.checked === id) {
        setValue("category", "");
        setChecked(dispatch, null);
      }

      toastMessage("Category deleted Successfully", "success");
    }
  };
  return (
    <Box>
      {category.length === 0 ? (
        <MDTypography variant="body2" component="p" color="text">
          Oops, No category found
        </MDTypography>
      ) : (
        <List>
          {category.map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem
                fullWidth
                key={value?.id}
                secondaryAction={
                  <Box>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={() => handleEdit(value)}
                    >
                      <CreateIcon className="cusmar" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={() => handleDelete(value?.id)}
                    >
                      <DeleteIcon className="cusmar" />
                    </IconButton>
                  </Box>
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={value?.id === controller?.checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    id={labelId}
                    primary={value?.name}
                    sx={{
                      marginRight: "1.3rem",
                      wordBreak: "break-word",
                    }}
                    primaryTypographyProps={{
                      sx: { fontSize: "14px" },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
}
