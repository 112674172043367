import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CategoryModal from "components/CategoryModal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { setChecked, useAdminPanelController } from "context";
import dayjs from "dayjs";
import ContentLayout from "layouts/content";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { jobFormSchema } from "schema/jobForm";
import { postJob } from "service/job";
import { dateForBackend } from "util/date";
const InputGroup = styled(MDInput)`
  .MuiOutlinedInput-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const NewJob = () => {
  const [categories, setCategories] = useState([]);
  const [notification, setNotification] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const openNotification = () => setNotification(true);
  const [controller, dispatch] = useAdminPanelController();
  useEffect(() => {
    setChecked(dispatch, null);
  }, []);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(jobFormSchema),
  });
  const handleNewJob = async (data) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const { job_title, category, job_description, job_expiry } = data;
    if (
      job_title !== "" &&
      category !== "" &&
      job_description !== "" &&
      job_expiry !== ""
    ) {
      const { id } = categories.find((item) => item.name === data.category);
      const job_expiry = dateForBackend(data.job_expiry);

      const response = await postJob({ ...data, id, job_expiry });
      if (response) {
        setChecked(dispatch, null);
        await reset({
          job_title: "",
          category: "",
          job_description: "",
          job_expiry: "",
        });

        openNotification();
      }
    }
    setSubmitting(false);
  };
  const renderNotification = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Job created successfully"
      dateTime="now"
      open={notification}
      onClose={() => {
        setNotification(false);
      }}
      close={() => {
        setNotification(false);
      }}
      bgWhite
    />
  );

  return (
    <>
      <ContentLayout>
        <MDBox
          maxWidth="sm"
          py={3}
          sx={{
            marginRight: {
              xs: 0,
              sm: 5,
              lg: 20,
              xl: 40,
            },
            marginLeft: {
              xs: 0,
              sm: 5,
              lg: 200,
              xl: 40,
            },
          }}
        >
          <Card>
            <Grid
              container
              sx={{
                padding: "50px",
              }}
              justifyContent={"center"}
              gap={2}
            >
              <Grid item xs={12}>
                <MDBox
                  sx={{
                    display: "flex",
                    gap: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <MDTypography variant="h4">Create New Job</MDTypography>
                </MDBox>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Job Title"
                  fullWidth
                  {...register("job_title")}
                  helperText={errors.job_title?.message || ""}
                  error={Boolean(errors?.job_title)}
                  onBlur={() => trigger("job_title")}
                />
              </Grid>

              <Grid item xs={12} className="cusmar1">
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        shrink={!!field.value}
                        error={Boolean(errors.category)}
                        id="input_categoryselected"
                        style={{
                          backgroundColor: "white",
                          padding: "0.2rem",
                        }}
                      >
                        Added Category
                      </InputLabel>
                      <TextField
                        {...field}
                        onBlur={() => trigger("category")}
                        InputProps={{
                          readOnly: true,
                        }}
                        error={Boolean(errors.category)}
                        helperText={errors.category?.message || ""}
                      />
                    </FormControl>
                  )}
                />
              </Grid>

              <CategoryModal
                setValue={setValue}
                setCategories={setCategories}
                categories={categories}
              />

              <Grid item xs={12} className="cusmar1">
                <TextField
                  label="Job Description"
                  {...register("job_description")}
                  multiline
                  rows={5}
                  fullWidth
                  helperText={errors.job_description?.message || ""}
                  error={errors?.job_description ? true : false}
                  onBlur={() => trigger("job_description")}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                      paddingTop: "7px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="job_expiry"
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <FormControl fullWidth variant="outlined">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Last date to apply "
                            sx={{
                              "& .MuiFormLabel-root": {
                                backgroundColor: "white",
                                padding: "0.2rem",
                              },
                            }}
                            format="DD-MM-YYYY"
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                helperText: errors.job_expiry?.message || "",
                                error: Boolean(errors.job_expiry),
                              },
                            }}
                            disablePast
                            inputRef={field.ref}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              field.onChange(date ? date.toDate() : null);
                            }}
                            onBlur={() => trigger("job_expiry")}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} className="ends">
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit(handleNewJob)}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Card>
          {renderNotification}
        </MDBox>
      </ContentLayout>
    </>
  );
};

export default NewJob;
