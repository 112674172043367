import { Box, Link, Typography } from "@mui/material";

function ErrorPage() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        height: "100vh",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", mt: 4 }}>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" sx={{ color: "gray.600", mt: 2 }}>
          We're sorry, but there seems to be an error.
        </Typography>
        <Link href="/" color="primary" sx={{ mt: 4, display: "block" }}>
          Go back to home
        </Link>
      </Box>
    </Box>
  );
}

export default ErrorPage;
