import * as Yup from "yup";

export const jobFormSchema = Yup.object({
  job_title: Yup.string()
    .trim()
    .required("Please enter job title")
    .min(3, "Title should be at least 3 characters")
    .max(50, "Title must be at most 50 characters"),
  category: Yup.string().required("Please select or add a category"),
  job_description: Yup.string()
    .trim()
    .required("Please provide a description")
    .min(3, "Description should be at least 3 characters")
    .max(500, "Description must be at most 500 characters"),
  job_expiry: Yup.date("Invalid date format")
    .typeError("Invalid date format")
    .required("Last date to apply is required")
    .nullable("Invalid date format")
    .transform((value, originalValue) => {
      if (originalValue === "") return null;
      return value;
    })
    .test(
      "date-check",
      "Date must be equal to or greater than today",
      function (value) {
        if (!value) return true;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const year = value.getFullYear();

        return value >= today; // Compare with current date
      }
    )
    .test("year-check", "Invalid date format", function (value) {
      if (!value) return true;
      const year = value.getFullYear();
      return year.toString().length === 4;
    }),
});
