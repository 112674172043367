import Cookies from "js-cookie";
import axios from "../util/axios";
import toastMessage from "../util/toast";
export const postLogin = async (data) => {
  try {
    const response = await axios.post("/auth/local", data);
    if (response?.data) {
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 2);
      Cookies.set("token", response?.data?.data?.token, {
        expires: expirationDate,
      });
      Cookies.set("refreshToken", response?.data?.data?.refresh_token, {
        expires: expirationDate,
      });
      Cookies.set("sessionId", response?.data?.data?.refresh_token, {
        expires: expirationDate,
      });
      return { message: response?.data?.message };
    }
  } catch (error) {
    if (error?.response?.data?.statusCode === 401) {
      toastMessage(error?.response?.data?.message, "error");
    } else {
      toastMessage(
        error?.response?.data?.message ||
          error?.message ||
          "something went wrong",
        "error"
      );
    }
  }
};
export const logout = async () => {
  try {
    const session_id = Cookies.get("sessionId");
    const response = await axios.post("/auth/logout", { session_id });
    if (response) {
      Cookies.remove("sessionId");
      Cookies.remove("refreshToken");
      Cookies.remove("token");
      return response;
    }
  } catch (error) {
    Cookies.remove("sessionId");
    Cookies.remove("refreshToken");
    Cookies.remove("token");
  }
};
