import { Divider, Icon, List } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import SidenavCollapse from "./SidenavCollapse";
import SideNavRoot from "./SidenavRoot";

import { setMiniSidenav, useAdminPanelController } from "context";
import { useEffect, useState } from "react";

import logo from "assets/images/logo_main.svg";
import { logout } from "service/auth";

const Sidenav = ({ routes, ...rest }) => {
  const [controller, dispatch] = useAdminPanelController();
  const [toggle, setToggle] = useState(false);
  const { miniSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const navigate = useNavigate();
  const items = [
    ...routes,
    {
      type: "collapse",
      name: "Logout",
      key: "logout",
      icon: <Icon fontSize="small">logout</Icon>,
    },
  ];
  let textColor = "white";
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const closeSidenav = () => setMiniSidenav(dispatch, true);
  useEffect(() => {
    if (collapseName === "jobs/new-job") {
      setToggle(true);
    }
  }, [collapseName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    // The event listener that's calling the handleMiniSidenav function when resizing the window.
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);
  const handleLogout = async () => {
    const response = await logout();

    navigate("/sign-in", { replace: true });
  };
  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = items.map(
    ({ type, name, icon, title, noCollapse, key, href, route, children }) => {
      let returnValue;

      if (type === "collapse" && name === "Jobs") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <div key={key} onClick={handleToggle}>
            <NavLink key={key} to={route}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={
                  collapseName.startsWith("jobs") &&
                  collapseName !== "jobs/new-job"
                }
              />
            </NavLink>
            {toggle &&
              children.map((item) => {
                return (
                  <NavLink key={item.key} to={item.route}>
                    <SidenavCollapse
                      name={item.name}
                      icon={item.icon}
                      active={item.key === collapseName}
                    />
                  </NavLink>
                );
              })}
          </div>
        );
      } else if (type === "collapse" && name === "Logout") {
        returnValue = (
          <div key={key} onClick={handleLogout}>
            <SidenavCollapse name={name} icon={icon} />;
          </div>
        );
      } else if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} light />;
      }

      return returnValue;
    }
  );

  return (
    <SideNavRoot
      {...rest}
      variant="permanent"
      ownerState={{ miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox component="img" src={logo} alt="Brand" width="10rem" />
          {/* 
          <MDBox sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {"Common Admin Panel"}
            </MDTypography>
          </MDBox> */}
        </MDBox>
      </MDBox>
      <Divider light />
      <List>{renderRoutes}</List>
    </SideNavRoot>
  );
};

Sidenav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
