import toastMessage from "util/toast";
import axios from "../util/axios";

export const getAllEnquires = async (offset, limit) => {
  try {
    const response = await axios.get("/enquiries", {
      params: {
        offset,
        limit,
        sort: JSON.stringify([["created_at", "desc"]]),
      },
    });

    return response?.data?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteEnquiry = async (id) => {
  try {
    const response = await axios.delete(`/enquiries/${id}`);
    return response?.data?.data;
  } catch (error) {
    toastMessage(
      error?.response?.data?.error || "Something went wrong",
      "error"
    );
  }
};
