import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "components/Navbar";
import DataTable from "components/Tables/DataTable";
// Data
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ErrorPage from "components/Error";
import Loading from "components/Loading";
import Pagination from "components/Pagination";
import ContentLayout from "layouts/content";
import { useEffect, useState } from "react";
import { getCategory } from "service/category";
import {
  appliedJobs,
  appliedJobsWithDeletedCategories,
  deleteAppliedJob,
} from "service/job";
import toastMessage from "util/toast";
import authorsTableData from "./data/authorsTableData";

function AppliedJobs() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setCategory] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(null);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [status, setStatus] = useState("");
  const handleCloseShow = () => {
    setShow(false);
    setId(null);
  };

  const perPageContent = process.env.REACT_APP_PER_PAGE_CONTENT || 10;
  const offset = (+currentPage - 1) * +perPageContent;
  const limit = perPageContent;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const category = await getCategory();
        if (category) {
          setCategories(category);
        }
        if (selectedCategory === "Archived") {
          const data = await appliedJobsWithDeletedCategories(
            offset,
            limit,
            status
          );
          setApplicants(data?.user_jobs || []);
          setTotalItems(data?.count || null);
        } else {
          const data = await appliedJobs(
            offset,
            limit,
            status,
            selectedCategory
          );
          if (data) {
            setApplicants(data?.user_jobs || []);
            setTotalItems(data?.count || null);
            setLoading(false);
          }
        }
      } catch (error) {
        setError(true);
      }
    };

    fetchData();
  }, [offset, selectedCategory, show, status]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = async (e) => {
    setCategory(e?.target?.value);
    setCurrentPage(1);
  };
  const handleStatus = async (e) => {
    setStatus(e?.target?.value);
    setCurrentPage(1);
  };

  const handleDelete = async () => {
    const response = await deleteAppliedJob(id);
    if (response) {
      setShow(false);
      toastMessage("Application deleted successfully", "success");
    }
  };
  const renderValue = (value) => {
    const maxLength = 12;
    return value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
  };
  const { columns, rows } = authorsTableData(
    handleClickOpen,
    setMessage,
    applicants,
    setId,
    setShow
  );
  if (error) {
    return <ErrorPage />;
  }
  return (
    <>
      <ContentLayout>
        <DashboardNavbar />
        {loading ? (
          <Loading />
        ) : (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Grid item xs={12} md={4}>
                        {" "}
                        <MDTypography variant="h6" color="white">
                          Applied Jobs
                        </MDTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className={"custom_flex"}
                      >
                        <FormControl className={"custom_select"}>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              backgroundColor: "#981B1B",
                              padding: "0.2rem",
                            }}
                          >
                            Job Category
                          </InputLabel>

                          <Select
                            sx={{
                              padding: "12px",
                            }}
                            className="custom-select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCategory}
                            label="Age"
                            renderValue={() => renderValue(selectedCategory)}
                            onChange={handleSelect}
                          >
                            <MenuItem value="">None</MenuItem>

                            <MenuItem value="Archived">Archived</MenuItem>
                            {categories.map((item) => {
                              return (
                                <MenuItem key={item?.id} value={item?.name}>
                                  {item?.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl className={"custom_select"}>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              backgroundColor: "#981B1B",
                              padding: "0.2rem",
                            }}
                          >
                            Status
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Age"
                            sx={{
                              padding: "12px",
                            }}
                            onChange={handleStatus}
                          >
                            {" "}
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>;
                            <MenuItem value="Expired">Expired</MenuItem>
                          </Select>
                        </FormControl>

                        <MDButton
                          className={"custom_button"}
                          variant="gradient"
                          color="success"
                          onClick={() => {
                            setStatus("");
                            setCategory("");
                          }}
                        >
                          Clear
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {applicants?.length === 0 && (
                      <Box
                        bgcolor="success"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingBottom: "1.5rem",
                        }}
                      >
                        <MDTypography
                          component="a"
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Oops !! No Result Found
                        </MDTypography>
                      </Box>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>

            {totalItems >= perPageContent && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalItems={totalItems}
              />
            )}
          </MDBox>
        )}
      </ContentLayout>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Comment"}</DialogTitle>
        <DialogContent
          maxWidth="md"
          sx={{
            minWidth: { xs: 300, sm: 400, md: 576 },
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          {message || ""}
        </DialogContent>

        <DialogActions className="d-flex bpopbg">
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleClose}
            autoFocus
          >
            Done
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={show}
        onClose={handleCloseShow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete job application
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete this job application?
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseShow} color="secondary">
            Cancel
          </MDButton>
          <MDButton color="primary" onClick={handleDelete}>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AppliedJobs;
