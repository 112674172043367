import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardNavbar from "components/Navbar";
import DataTable from "components/Tables/DataTable";

// Data
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ErrorPage from "components/Error";
import Loading from "components/Loading";
import MDButton from "components/MDButton";
import Pagination from "components/Pagination";
import ContentLayout from "layouts/content";
import { useEffect, useState } from "react";
import { deleteEnquiry, getAllEnquires } from "service/enquiries";
import toastMessage from "util/toast";
import authorsTableData from "./data/authorsTableData";

function Enquiries() {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [enquiries, setEnquiries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [id, setId] = useState(null);
  const perPageContent = process.env.REACT_APP_PER_PAGE_CONTENT;
  const offset = (+currentPage - 1) * +perPageContent;
  const limit = perPageContent;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseShow = () => {
    setShow(false);
    setId(null);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteEnquiry(id);
      if (response) {
        setShow(false);
        toastMessage("Enquiry deleted successfully", "success");
      }
    } catch (error) {}
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllEnquires(offset, limit);
        if (data) {
          setEnquiries(data?.enquiries || []);
          setLoading(false);
          setTotalItems(data?.count || null);
        }
      } catch (error) {
        setError(true);
      }
    };

    fetchData();
  }, [offset, limit, show]);
  const { columns, rows } = authorsTableData(
    handleClickOpen,
    setMessage,
    enquiries,
    setId,
    setShow
  );
  if (error) {
    return <ErrorPage />;
  }
  return (
    <ContentLayout>
      <DashboardNavbar />
      {loading ? (
        <Loading />
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Grid item xs={12} md={4}>
                      {" "}
                      <MDTypography variant="h6" color="white">
                        Enquiries
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                  {enquiries?.length === 0 && (
                    <Box
                      bgcolor="success"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "1.5rem",
                      }}
                    >
                      <MDTypography
                        component="a"
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                      >
                        Oops !! No Result Found
                      </MDTypography>
                    </Box>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>

          {totalItems >= perPageContent && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItems={totalItems}
            />
          )}
        </MDBox>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent
          maxWidth="md"
          sx={{
            minWidth: { xs: 300, sm: 400, md: 576 },
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          {message || ""}
        </DialogContent>

        <DialogActions className="d-flex bpopbg">
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleClose}
            autoFocus
          >
            Done
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={show}
        onClose={handleCloseShow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete enquiry</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this enquiry?
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseShow} color="secondary">
            Cancel
          </MDButton>
          <MDButton color="primary" onClick={handleDelete}>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </ContentLayout>
  );
}

export default Enquiries;
