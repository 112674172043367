import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ErrorPage from "components/Error";
import Loading from "components/Loading";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "components/Navbar";
import Pagination from "components/Pagination";
import DataTable from "components/Tables/DataTable";
import ContentLayout from "layouts/content";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCategory } from "service/category";
import { deleteJob, getAllJob } from "service/job";
import toastMessage from "util/toast";
import authorsTableData from "./data/authorsTableData";

function Jobs() {
  const [currentPage, setCurrentPage] = useState(1);
  const perPageContent = process.env.REACT_APP_PER_PAGE_CONTENT;
  const offset = (+currentPage - 1) * +perPageContent;
  const limit = perPageContent;
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [totalItems, setTotalItems] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const handleCloseShow = () => {
    setShow(false);
    setId(null);
  };
  const handleDelete = async () => {
    const response = await deleteJob(id);
    if (response) {
      setShow(false);
      setId(null);
      toastMessage("Successfully deleted", "success");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllJob(
          offset,
          limit,
          selectedCategory,
          status,
          categoryId
        );
        const category = await getCategory();
        if (category) {
          setCategories(category);
        }
        if (data) {
          setJobs(data?.job_masters || []);
          setTotalItems(data?.count || null);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
      }
    };

    fetchData();
  }, [offset, selectedCategory, status, show]);
  const { columns, rows } = authorsTableData(jobs, setId, setShow);

  const handleSelect = async (e) => {
    setCategory(e?.target?.value);
    const category = categories.find((obj) => obj.name === e?.target?.value);
    setCategoryId(category?.id);
    setCurrentPage(1);
  };
  const renderValue = (value) => {
    const maxLength = 12;
    return value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
  };
  const handleStatus = async (e) => {
    setStatus(e?.target?.value);
    setCurrentPage(1);
  };
  if (error) {
    return <ErrorPage />;
  }
  return (
    <ContentLayout>
      <DashboardNavbar />
      {loading ? (
        <Loading />
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Grid item xs={12} md={12}>
                      {" "}
                      <MDTypography variant="h6" color="white">
                        Jobs
                      </MDTypography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={"custom_flex"}
                    >
                      <FormControl className={"custom_select"}>
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{
                            backgroundColor: "#981B1B",
                            padding: "0.2rem",
                          }}
                        >
                          Job Category
                        </InputLabel>

                        <Select
                          style={{
                            padding: "12px",
                            color: "green",
                          }}
                          className="custom-select"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedCategory}
                          label="Age"
                          renderValue={() => renderValue(selectedCategory)}
                          onChange={handleSelect}
                        >
                          <MenuItem value="">None</MenuItem>
                          {categories.map((item) => {
                            return (
                              <MenuItem key={item?.id} value={item?.name}>
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <FormControl className={"custom_select"}>
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{
                            backgroundColor: "#981B1B",
                            padding: "0.2rem",
                          }}
                        >
                          Status
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          label="Age"
                          sx={{
                            padding: "12px",
                          }}
                          onChange={handleStatus}
                        >
                          {" "}
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>;
                          <MenuItem value="Expired">Expired</MenuItem>
                        </Select>
                      </FormControl>

                      <MDButton
                        className={"custom_button"}
                        variant="gradient"
                        color="success"
                        onClick={() => {
                          setStatus("");
                          setCategory("");
                          setCategoryId(null);
                        }}
                      >
                        Clear
                      </MDButton>

                      <Link to="/jobs/new-job">
                        <MDButton
                          className={"custom_button"}
                          variant="gradient"
                          color="success"
                        >
                          <Icon>add</Icon> &nbsp; Create New Job
                        </MDButton>
                      </Link>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          {totalItems >= perPageContent && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItems={totalItems}
            />
          )}
        </MDBox>
      )}
      <Dialog
        open={show}
        onClose={handleCloseShow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete job</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this job ?
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseShow} color="secondary">
            Cancel
          </MDButton>
          <MDButton color="primary" onClick={handleDelete}>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </ContentLayout>
  );
}

export default Jobs;
