import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const NotFoundPage = () => {
  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h1" component="h1" color="error" gutterBottom>
          404
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Oops! The page you're looking for does not exist.
        </Typography>
        <Link to="/" color="primary" underline="hover">
          Go back to homepage
        </Link>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
