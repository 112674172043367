import axios from "../util/axios";

export const getCounts = async () => {
  try {
    const response = await axios.get("/user/statistics");
    return response?.data?.data?.users[0];
  } catch (error) {
    throw new Error(error);
  }
};
