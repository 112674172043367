import { createContext, useContext, useMemo, useReducer } from "react";

import PropTypes from "prop-types";

const AdminPanel = createContext();
AdminPanel.displayName = "AdminPanelContext";

function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "CHECKED": {
      return { ...state, checked: action.value };
    }
    case "TOAST": {
      return { ...state, checked: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AdminPanelControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    sidenavColor: "info",
    fixedNavbar: true,
    darkMode: false,
    checked: false,
    toast: true,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <AdminPanel.Provider value={value}>{children}</AdminPanel.Provider>;
}

function useAdminPanelController() {
  const context = useContext(AdminPanel);

  if (!context) {
    throw new Error(
      "useAdminPanelController should be used inside the AdminPanelControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
AdminPanelControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setChecked = (dispatch, value) => dispatch({ type: "CHECKED", value });
const setToast = (dispatch, value) => dispatch({ type: "TOAST", value });
export {
  AdminPanelControllerProvider,
  setChecked,
  setDarkMode,
  setFixedNavbar,
  setLayout,
  setMiniSidenav,
  setSidenavColor,
  setToast,
  setTransparentNavbar,
  useAdminPanelController,
};
