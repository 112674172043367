import { Icon } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import download from "downloadjs";
import { getDate } from "util/date";
import toastMessage from "util/toast";

// Images
const team =
  "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80";

export default function data(applicants, setMessage, handleClickOpen) {
  const handleClick = (message) => {
    setMessage(message);
    handleClickOpen();
  };
  const handleDownloadPDF = (pdfUrl) => {
    const fileName = pdfUrl?.split(".com/")[1];
    fetch(pdfUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        download(blob, fileName, "application/pdf");
      })
      .catch((error) => {
        toastMessage("Error downloading PDF", "error");
      });
  };
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Applicant Name", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Phone", accessor: "phone", align: "left" },
      { Header: "Date", accessor: "date", align: "center" },
      { Header: "Comment", accessor: "comment", align: "center" },
      { Header: "Resume", accessor: "resume", align: "center" },
    ],

    rows:
      applicants.length === 0
        ? [
            {
              name: null,

              email: null,

              phone: null,

              date: null,

              comment: null,
              resume: null,
            },
          ]
        : applicants.map((item) => {
            return {
              name: (
                <MDTypography
                  component="a"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {item?.name}
                </MDTypography>
              ),

              email: (
                <MDTypography
                  component="a"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {item?.email}
                </MDTypography>
              ),

              phone: (
                <MDTypography
                  component="a"
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {item?.phone_number}
                </MDTypography>
              ),

              date: (
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                >
                  {item?.created_at
                    ? getDate(item?.created_at.split("T")[0])
                    : null}
                </MDTypography>
              ),

              comment: (
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  sx={{ cursor: "pointer" }}
                  fontWeight="medium"
                  onClick={() => handleClick(item?.message)}
                >
                  <Icon fontSize="small">comment</Icon>
                </MDTypography>
              ),

              resume: (
                <MDTypography
                  component="a"
                  href={item?.resume?.endsWith(".pdf") ? null : item?.resume}
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  sx={{ cursor: "pointer" }}
                >
                  <Icon
                    onClick={
                      item?.resume?.endsWith(".pdf")
                        ? () => handleDownloadPDF(item?.resume)
                        : null
                    }
                    fontSize="small"
                  >
                    file_download
                  </Icon>
                </MDTypography>
              ),
            };
          }),
  };
}
