import toastMessage from "util/toast";
import axios from "../util/axios";

export const getCategory = async () => {
  try {
    const response = await axios.get("/job_categorys", {
      params: {
        limit: -1,
        where: JSON.stringify({ active: true }),
        sort: JSON.stringify([["created_at", "desc"]]),
      },
    });
    return response?.data?.data?.job_categorys;
  } catch (error) {
    throw new Error(error);
  }
};

export const postCategory = async (category) => {
  try {
    const response = await axios.post("/job_categorys", { name: category });
    const data = response?.data?.data?.job_categorys;
    return data;
  } catch (error) {
    console.log(error);
    toastMessage(
      error?.response?.data?.error?.message || "Something went wrong",
      "error"
    );
  }
};

export const putCategory = async (category, id) => {
  try {
    const response = await axios.put(`/job_categorys/${id}`, {
      name: category,
    });

    const data = response?.data?.data?.job_categorys;

    return data;
  } catch (error) {
    toastMessage(
      error?.response?.data?.error || "Something went wrong",
      "error"
    );
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await axios.delete(`/job_categorys/${id}`);

    return response?.data?.data?.job_categorys;
  } catch (error) {
    toastMessage(
      error?.response?.data?.error || "Something went wrong",
      "error"
    );
  }
};
