import dayjs from "dayjs";
import "dayjs/locale/en";
import dayjsTz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
export const getDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

export const convertDate = (dateString) => {
  const dateParts = dateString.split("-");
  const dd = dateParts[2];
  const mm = dateParts[1];
  const yyyy = dateParts[0];

  const convertedDate = `${dd}-${mm}-${yyyy}`;

  return convertedDate;
};
export const dateForBackend = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const getUTC = () => {
  dayjs.extend(dayjsTz);
  dayjs.extend(utc);
  const guessedTimeZone = dayjs.tz.guess();
  const currentTimeInGuessedTimeZone = dayjs().tz(guessedTimeZone);
  const utcOffset = currentTimeInGuessedTimeZone.format("Z");
  return utcOffset;
};
