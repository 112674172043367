import Navbar from "components/Navbar";
import ContentLayout from "layouts/content";

import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsLineChartData from "./data/reportsLineChartData";

// components
import { Grid } from "@mui/material";
import Loading from "components/Loading";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCounts } from "service/counts";

const Dashboard = () => {
  const { sales, tasks } = reportsLineChartData;
  const [counts, setCounts] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        const data = await getCounts();
        if (data) {
          setCounts(data);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
      }
    })();
  }, []);

  return (
    <ContentLayout>
      <Navbar />
      {loading ? (
        <Loading />
      ) : (
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <Link to="/applied-jobs">
                  <ComplexStatisticsCard
                    color="dark"
                    icon="weekend"
                    title="New applications"
                    count={counts?.unseen_jobs}
                  />
                </Link>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <Link to="/jobs">
                  <ComplexStatisticsCard
                    icon="leaderboard"
                    title="Jobs"
                    count={counts?.total_jobs}
                  />
                </Link>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <Link to="/jobs">
                  <ComplexStatisticsCard
                    color="success"
                    icon="store"
                    title="Categories"
                    count={counts?.total_categories}
                  />
                </Link>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <Link to="/enquiries">
                  <ComplexStatisticsCard
                    color="primary"
                    icon="person_add"
                    title="Enquiries"
                    count={counts?.enquiries}
                  />
                </Link>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </ContentLayout>
  );
};

export default Dashboard;
