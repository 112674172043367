import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";

function Pagination({ currentPage, setCurrentPage, totalItems }) {
  const itemsPerPage = process.env.REACT_APP_PER_PAGE_CONTENT;
  const totalPages = Math.ceil(totalItems / itemsPerPage); // Calculate the total number of pages based on your data

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <MDPagination
          key={i}
          item
          active={currentPage === i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </MDPagination>
      );
    }

    return items;
  };

  return (
    <MDBox py={3}>
      <MDPagination size="medium">
        <MDPagination
          item
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <Icon>keyboard_arrow_left</Icon>
        </MDPagination>
        {renderPaginationItems()}
        <MDPagination
          item
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <Icon>keyboard_arrow_right</Icon>
        </MDPagination>
      </MDPagination>
    </MDBox>
  );
}

export default Pagination;
