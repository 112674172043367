import { yupResolver } from "@hookform/resolvers/yup";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LogoImage from "assets/images/logo_main.svg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import PageLayout from "layouts/page";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { loginFormSchema } from "schema/loginForm";
import { postLogin } from "service/auth";
function SignIn() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(loginFormSchema),
  });
  const handleLogin = async (data) => {
    const response = await postLogin(data);
    if (response?.message) {
      reset();
      navigate("/", { replace: true });
    }
  };

  return (
    <PageLayout>
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img src={LogoImage} alt="file" />

                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Sign in
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      {...register("username")}
                      fullWidth
                      helperText={errors.username?.message || ""}
                      error={Boolean(errors.username)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      {...register("password")}
                      label="Password"
                      fullWidth
                      helperText={errors.password?.message || ""}
                      error={Boolean(errors.password)}
                    />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSubmit(handleLogin)}
                    >
                      sign in
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default SignIn;
