import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import ListChecked from "components/ListChecked";
import MDButton from "components/MDButton";
import { setChecked, useAdminPanelController } from "context";
import { useEffect, useState } from "react";
import { getCategory, postCategory, putCategory } from "service/category";

const CategoryModal = ({ setValue, setCategories, categories }) => {
  const [open, setOpen] = useState(false);

  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [message, setMessage] = useState("");
  const [controller, dispatch] = useAdminPanelController();
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCategory();
      if (response) {
        setCategories(response);
      }
    };

    fetchData();
  }, [open]);
  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMessage("");
    setCategory("");
    setCategoryId(null);
    setOpen(false);
  };
  const handleChange = async (e) => {
    const inputValue = e?.target?.value;
    if (inputValue.length < 3) {
      setMessage("Minimum 3 characters required");
      setCategory(inputValue);
      return;
    } else if (inputValue.length > 40) {
      setMessage("Maximum 40 characters allowed");
      setCategory(inputValue);
      return;
    }
    setCategory(inputValue);
    setMessage("");
  };

  const handleEditCategory = async () => {
    const inputValue = category.trim();

    if (!inputValue) {
      setMessage("Category is required");
      return;
    } else if (inputValue.length < 3) {
      setMessage("Minimum 3 characters required");
      return;
    } else if (inputValue.length > 40) {
      setMessage("Maximum 40 characters allowed");
      return;
    }
    const data = await putCategory(inputValue, categoryId);
    if (data) {
      if (data?.id === controller?.checked) {
        setValue("category", data?.name, { shouldValidate: true });
      }
      setCategory("");
      setCategoryId(null);
      const index = categories.findIndex(
        (category) => category?.id === categoryId
      );
      const updatedCategory = [...categories];
      updatedCategory[index] = data;
      setCategories(updatedCategory);
    }
  };
  const handleNewCategory = async () => {
    const inputValue = category.trim();

    if (!inputValue) {
      setMessage("Category is required");
      return;
    } else if (inputValue.length < 3) {
      setMessage("Minimum 3 characters required");
      return;
    } else if (inputValue.length > 40) {
      setMessage("Maximum 40 characters allowed");
      return;
    }
    const data = await postCategory(inputValue);

    if (data) {
      setChecked(dispatch, data?.id);
      setValue("category", data?.name, { shouldValidate: true });
      setOpen(false);
      setCategory("");
    }
  };
  return (
    <>
      <Grid item xs={12}>
        <MDButton fullWidth color="info" onClick={handleClickOpen}>
          Select/Add Category
        </MDButton>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ boxSizing: "unset", width: { xs: "88%", sm: "95%" } }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Select or Add a New Category"}
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: "31vh",
            minWidth: { md: "30rem" },
            boxSizing: "unset",
            overflowY: "scroll",
            overflowX: "hidden",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <ListChecked
            setValue={setValue}
            category={categories}
            setCategory={setCategory}
            setCategoryId={setCategoryId}
            setCategories={setCategories}
            setOpen={setOpen}
          />
        </DialogContent>
        <DialogActions className="d-flex between">
          <Grid item xs={12}>
            <TextField
              label={categoryId ? "Update category" : "Create New category"}
              value={category}
              fullWidth
              sx={{
                "& .MuiFormLabel-root": {
                  backgroundColor: "white",
                  padding: "0.2rem",
                },
              }}
              onChange={(e) => handleChange(e)}
              helperText={message || ""}
              error={Boolean(message)}
            />
          </Grid>

          <Divider />
          {categoryId ? (
            <MDButton
              color="info"
              autoFocus
              onClick={handleEditCategory}
              sx={{ marginLeft: "0.5rem" }}
            >
              Update
            </MDButton>
          ) : (
            <MDButton
              color="info"
              autoFocus
              onClick={handleNewCategory}
              sx={{
                marginLeft: "0.5rem",
                marginBottom: message !== "" ? "1rem" : "0",
              }}
            >
              Save
            </MDButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoryModal;
